import { AxiosResponse } from 'axios';
import { apiClient } from '@/utils';
import { flatten } from 'lodash';
import { CustomField, CustomFieldValueType, Service, PriceTimeUnit } from '../types';
import {
    format,
    isSameDay,
    subDays,
    subHours,
    subMinutes,
    setHours,
    setMinutes,
    getHours,
    getMinutes,
    toDate,
    eachDayOfInterval,
    addWeeks,
    getISODay,
    isWithinInterval,
    formatDistanceStrict,
    addDays,
    addMinutes,
} from './datetime';
import { RequirementData } from '@/components/Loader';
import { addHours, intervalToDuration } from 'date-fns';
import { SemanticICONS } from 'semantic-ui-react';
import { stdnum } from 'stdnum';
import { TranslateFunction } from 'react-localize-redux';

interface ParsedSeconds {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

export function parseSeconds(inputSeconds: number): ParsedSeconds {
    let seconds = inputSeconds;
    var days = Math.floor(inputSeconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    var hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    var minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    return { days, hours, minutes, seconds };
}

export function formattedFromTo(
    from: Date | string,
    to: Date | string,
    includeDayIfSameDay: boolean = true
): string {
    if (isSameDay(from, to)) {
        if (includeDayIfSameDay) {
            return `${format(from, 'PPp')} - ${format(to, 'p')}`;
        } else {
            return `${format(from, 'p')}`;
        }
    } else if(isWithinInterval(new Date(to), { start: from, end: addHours(new Date(from), 24)})) {
       return `${format(from, "p")} - ${format(to, "p")}`;
    } else {
        return `${format(from, 'PPp')} - ${format(to, 'PPp')}`;
    }
}

// Create an array with all elements in fullArray where all elements
// in selectionsArray have selectionAttribute = true, all other false.
export function createSelectionArrayFromFull(
    fullArray: Array<any> = [],
    selectionsArray: Array<any> = [],
    selectionAttribute: string
): Array<any> {
    return fullArray.map((fullArrayElement) => {
        const selected = selectionsArray.find(
            (selectedElement) => selectedElement.Id === fullArrayElement.Id
        );

        return {
            ...(selected || {}),
            ...fullArrayElement,
            [selectionAttribute]: !!selected,
        };
    });
}

interface PopulatedCustomField {
    Id: number;
    Value: any;
    meta: CustomField;
}

export function populateCustomFields(
    fields: CustomField[] = [],
    customFieldValues: CustomFieldValueType[] = [],
    useDefaultValues: boolean = false
): PopulatedCustomField[] {
    return fields.map((field: CustomField) => {
        const valueObject = customFieldValues.find((valueObj) => valueObj.Id === field.Id);

        let value;

        if (valueObject?.Value) {
            value = valueObject.Value;
        } else if (field.DefaultValue && useDefaultValues) {
            value = field.DefaultValue;
        }

        return {
            Id: field.Id,
            Value: value,
            meta: field,
        };
    });
}

export function lastTimeToUnbook(service: Service, date: Date | string) {
    let lastTimeToUnbookDate = toDate(date);

    lastTimeToUnbookDate = subDays(lastTimeToUnbookDate, service.UnbookBeforeDays || 0);
    lastTimeToUnbookDate = subHours(lastTimeToUnbookDate, service.UnbookBeforeHours || 0);
    lastTimeToUnbookDate = subMinutes(lastTimeToUnbookDate, service.UnbookBeforeMinutes || 0);

    return format(lastTimeToUnbookDate, 'PPp');
}

export const languageCodeToFlagCode = (code: string) => {
    switch (code) {
        case 'en':
            return 'gb';
        case 'sv':
            return 'se';
        default:
            return code;
    }
};

export const getFullLanguageCode = (language: string) => {
    switch (language) {
      case 'sv':
        return 'sv-se';
      case 'no':
        return 'no';
      case 'en':
        return 'en-us';
      case 'fi':
        return 'fi';
      default:
        return 'sv-se';
    }
  }

  export const getTimeZoneFromLanguageCode = (code: string) => {
    switch (code) {
        case 'sv':
          return 'SE';
        case 'no':
          return 'NO';
        case 'en':
          return 'GB';
        case 'fi':
          return 'FI';
        default:
          return 'SE';
      }
  }

// Use it for nested objects inside querystring for GET requests
export const stringifyNestedParams = (params: Object): string =>
    params ? JSON.stringify(params).replace(/"/gi, '') : '';

export function getRepeatedDates(
    originalDate: Date,
    daysOfWeek: number[] = [],
    numberOfWeeks: number
): Date[] {
    return eachDayOfInterval({
        start: originalDate,
        end: addWeeks(originalDate, numberOfWeeks),
    })
        .filter((date) => daysOfWeek.includes(getISODay(date)))
        .map((date) => setHours(date, getHours(originalDate)))
        .map((date) => setMinutes(date, getMinutes(originalDate)));
}


const TAKE = 300;
export async function getAllItems(initialResponse: AxiosResponse) {
    let take = initialResponse.config.params.Take || TAKE;
    let results: any[] = [];
    for (let i = 0; results.length < initialResponse.data.Total; i++) {
        let config = {...initialResponse.config};
        config.params.Take = take;
        config.params.Skip = take * i; 
        const response: AxiosResponse = await apiClient.request(initialResponse.config);
        results = flatten([results, response.data.Results])
    }
    
    return results;
}

export async function clearSession(clientId: string, refreshToken: string) {
    var myHeaders = new Headers();
    var urlencoded = new URLSearchParams();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  
    urlencoded.append('refresh_token', refreshToken);
    urlencoded.append("client_id", clientId);
  
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
  
    return fetch(
      `${process.env.REACT_APP_KEYCLOAK_AUTH_URL}/realms/${process.env.REACT_APP_KEYCLOAK_REALM}/protocol/openid-connect/logout`,
      requestOptions
    );
  }

  export const adaptQueryToLoader = (query: any): RequirementData => {
    return {
        error: query.error as any,
        hasError: query.isError as boolean,
        isLoaded: query.isLoaded as boolean,
        isLoading: query.isLoading as boolean,
        requestIsHidden: false
    }
  }

  export const getVismaIconName = (icon: SemanticICONS): string => {
    switch (icon.toLowerCase()) {
        case 'tasks':
            return 'single-selection';
        case 'envelope':
            return 'email';
        case 'eye':
            return 'preview';
        default:
            return icon.toLowerCase();
    }
  }

  export const getDateWithTime = (minutes: number) => {
    let rootDate = new Date();
    
    const duration = intervalToDuration({ start: 0, end: minutes * 60 * 1000 });

    rootDate.setHours(duration.hours as number);
    rootDate.setMinutes(duration.minutes as number);

    return rootDate;
}

export function isNumeric(str: string | number) {
    if (typeof str != "string") return false // we only process strings!
    // @ts-ignore
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

export const formatMinutePriceToTimeUnit = (val: number, unit: PriceTimeUnit) => {
    if(unit === 'hour') {
        return Math.round((val * 60));
    } else if (unit === 'day') {
        return Math.round((val * (60 * 24)));
    }

    return val;
}

export const parseMinutePriceToTimeUnit = (val: number, unit: PriceTimeUnit) => {
    if(unit === 'hour') {
        return (val / 60);
    } else if (unit === 'day') {
        return (val / (60 * 24));
    }

    return val;
}

export const minuteToTimeUnit = (val: number /** in minutes */, unit: PriceTimeUnit): number => {
    if(unit === 'hour') {
        return val / 60;
    } else if (unit === 'day') {
        return val / (60 * 24)
    }

    return val;
};

export const timeUnitToMinute = (val: number, unit: PriceTimeUnit): number => {
    if(unit === 'hour') {
        return val * 60;
    } else if (unit === 'day') {
        return val * (60 * 24)
    }

    return val;
}

export const convertTime = (fromUnit: PriceTimeUnit, toUnit: PriceTimeUnit, value: number): number => {
    if(fromUnit === 'minute' && toUnit === 'hour') {
        return value / 60;
    } else if(fromUnit === 'minute' && toUnit === 'day') {
        return value / (60 * 24)
    } else if(fromUnit === 'hour' && toUnit === 'minute') {
        return value * 60;
    } else if(fromUnit === 'hour' && toUnit === 'day') {
        return value / 24;
    } else if(fromUnit === 'day' && toUnit === 'minute') {
        return value * 60 * 24;
    } else if(fromUnit === 'day' && toUnit === 'hour') {
        return value * 24;
    }

    return value;
}

export const getUnitFromCalculationTypeId = (id: number) : PriceTimeUnit => {
    switch (id) {
        case 1:
            return 'service';
        case 2:
            return 'minute';
        case 3:
            return 'hour';
        case 4:
            return 'day';
        default:
            return 'service';
    }
}

export const formatToLocalCurrencyValue = (value: number, currency = '') => {
    switch (currency) {
        case 'kr':
            return `${value}${currency}`;
        case '$':
            return `${currency}${value}`;
        case '€':
            return `${value}${currency}`;
        default:
            return `${value}${currency}`;
    }
}

const EACCOUNTING_INVOICE_URL_BASE = `${process.env.REACT_APP_EACCOUNTING_API_URL}/#/sales/customerinvoice`;
const EACCOUNTING_INVOICE_DRAFT_URL_BASE = `${process.env.REACT_APP_EACCOUNTING_API_URL}/#/sales/customerinvoicedraft`;

export const getInvoiceUrl = (invoiceId: string, draft = false) => {
    let invoiceBaseUrl = draft ? EACCOUNTING_INVOICE_DRAFT_URL_BASE : EACCOUNTING_INVOICE_URL_BASE;
    
    return `${invoiceBaseUrl}/${invoiceId}`;
}

export function isOrganisationNumberValid(value: string) {
    return (
        // // Sweden
        stdnum.SE.orgnr.validate(value).isValid ||
        stdnum.SE.personnummer.validate(value).isValid ||
        // // Denmark
        stdnum.DK.cpr.validate(value).isValid ||
        stdnum.DK.cvr.validate(value).isValid ||
        // // Norway
        stdnum.NO.orgnr.validate(value).isValid ||
        stdnum.NO.fodselsnummer.validate(value).isValid ||
        // // Finland
        stdnum.FI.hetu.validate(value).isValid ||
        stdnum.FI.ytunnus.validate(value).isValid
    );
}

export function getRebateCodeType(rebateCodeTypeId: number, translate: TranslateFunction) {
    switch (rebateCodeTypeId) {
        case 1:
            return translate('promoCodes.rebateCodeTypeId.1.text');
        case 2:
            return translate('promoCodes.rebateCodeTypeId.2.text');
        case 3:
            return translate('promoCodes.rebateCodeTypeId.3.text');
        case 4:
            return translate('promoCodes.rebateCodeTypeId.4.text');
        case 5:
            return translate('promoCodes.rebateCodeTypeId.5.text');
        default:
            return null;
    }
}