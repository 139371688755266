import { createAction, createAsyncThunk, createListenerMiddleware, createSlice, TypedStartListening } from '@reduxjs/toolkit';
import { definitions } from '@/apitypes';
import { CalculatePriceQuery, Quantity, Time, TotalPriceInformationResponse } from '@/types';
import { AxiosResponse } from 'axios';
import { apiClient, throwSubmissionError } from '@/utils';
import { clearSubmitErrors, reset as resetForm } from 'redux-form';
import { ApplicationDispatch, ApplicationState } from '@/store';
import Notifications from 'react-notification-system-redux';
import { getTranslate } from 'react-localize-redux';
import { omit } from 'lodash'
import { toDate } from '@/utils/datetime';

export type CalculatedPriceEntityElement = TotalPriceInformationResponse & { Quantity?: number, PriceId?: number };
interface State {
    entity: { [key in string] : CalculatedPriceEntityElement } | null;
    loading: boolean;
    error: any;
}
const initialState: State = {
    entity: null,
    loading: false,
    error: null,
};

export const calculatePriceListener = createListenerMiddleware();
export type CalculatePriceStartListening = TypedStartListening<ApplicationState, ApplicationDispatch>;
const startCalculatePriceListener = calculatePriceListener.startListening as CalculatePriceStartListening;

// Thunks

export const applyRebateCode = createAsyncThunk(
    '/services/calculatePrice/APPLY_REBATE_CODE',
    async ({
        rebateCodeSign,
        interval,
        quantities = [],
        customerEmail,
        serviceId,
        singlePrice = false
    } : {
        rebateCodeSign: string,
        interval: Time,
        quantities: Quantity[],
        customerEmail?: string,
        serviceId: number,
        singlePrice?: boolean
    }, thunkApi) => {
        try {
            const state = thunkApi.getState() as ApplicationState;
            const translate = getTranslate(state.localize);
            const companyId = state.company.data?.Id;
            
            const priceId = quantities[0].PriceId || '';
            const key = `${interval.From.toString()}_${priceId}`;

            const _quantities =
                state.calculatePrice?.entity &&
                Object.values(omit(state.calculatePrice?.entity, 'appliedCodes')).length > 0
                    ? Object.values(omit(state.calculatePrice.entity, 'appliedCodes')).map((c) =>
                          c.PriceId === quantities[0].PriceId
                              ? {
                                    Quantity: quantities[0].Quantity,
                                    PriceId: c.PriceId,
                                }
                              : {
                                    Quantity: c.Quantity,
                                    PriceId: c.PriceId,
                                }
                      )
                    : quantities;

            const alreadyAppliedCode =
                state.calculatePrice.entity &&
                Object.values(state.calculatePrice.entity).some(p => 
                    p.AppliedCodes.map(
                        (a) => a.RebateCodeSign
                    ).includes(rebateCodeSign)
                );
            
            if(alreadyAppliedCode) {
                return thunkApi.rejectWithValue(throwSubmissionError({
                    _error: translate('newBookingForm.codeAlreadyApplied')
                }));
            }

            const getbysignResponse: AxiosResponse = await apiClient.get('/rebatecodes/getbysign', {
                params: {
                    RebateCodeSign: rebateCodeSign,
                    Date: toDate(new Date(interval.From)).toISOString(),
                    CustomerEmail: customerEmail,
                    ServiceId: serviceId,
                    CompanyId: companyId,
                },
            });

            const calculatePriceResponse: AxiosResponse<
                definitions['TotalPriceInformationResponse']
            > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
                Id: serviceId,
                Interval: interval,
                Quantities: quantities || [],
                RebateCodeIds: [getbysignResponse.data.Id],
                CustomerEmail: customerEmail,
            });

            return thunkApi.fulfillWithValue({
                key,
                single: singlePrice,
                data: calculatePriceResponse.data as TotalPriceInformationResponse,
            });
        } catch (error: any) {
            thunkApi.dispatch(
                Notifications.show(
                    { title: error.response?.data?.ResponseStatus?.Message as string },
                    'error'
                )
            );
            return thunkApi.rejectWithValue(throwSubmissionError(error));
        }
    }
);

export const removeRebateCode = createAsyncThunk(
    '/services/calculatePrice/REMOVE_REBATE_CODE',
    async ({
        rebateCodeSign,
        interval,
        quantities = [],
        customerEmail,
        serviceId,
        singlePrice = false
    } : {
        rebateCodeSign: string,
        interval: Time,
        quantities: Quantity[],
        customerEmail: string,
        serviceId: number,
        singlePrice?: boolean
    }, thunkApi) => {
        try {
            const priceId = quantities[0].PriceId || '';
            const key = `${interval.From.toString()}_${priceId}`;

            const state = thunkApi.getState() as ApplicationState;
            const rebateCodeIds = state.calculatePrice.entity && state.calculatePrice.entity[key]
                ? state.calculatePrice.entity[key].AppliedCodes.filter(code => code.RebateCodeSign === rebateCodeSign)
                : []

            // debugger;

            const calculatePriceResponse: AxiosResponse<
                definitions['TotalPriceInformationResponse']
            > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
                Id: serviceId,
                Interval: interval,
                Quantities: quantities || [],
                RebateCodeIds: rebateCodeIds.filter(code => code.RebateCodeSign === rebateCodeSign),
                CustomerEmail: customerEmail,
            });

            return thunkApi.fulfillWithValue({
                key,
                single: singlePrice,
                data: calculatePriceResponse.data as TotalPriceInformationResponse,
            });
        } catch (error: any) {
            thunkApi.dispatch(
                Notifications.show(
                    { title: error.response?.data?.ResponseStatus?.Message as string },
                    'error'
                )
            );
            return thunkApi.rejectWithValue(throwSubmissionError(error));
        }
    }
);

export const calculatePrice = createAsyncThunk(
    '/services/calculatePrice/CALCULATE_PRICE',
    async ({
        interval,
        quantities = [],
        customerEmail,
        serviceId,
        rebateCodeIds = [],
        single = false
    } : {
        interval: Time,
        quantities: Quantity[],
        customerEmail?: string,
        serviceId: number,
        rebateCodeIds?: number[],
        single?: boolean
    }, thunkApi) => {
        try {
            const priceId = quantities[0].PriceId || '';
            const key = `${interval.From.toString()}_${priceId}`;

            const calculatePriceResponse: AxiosResponse<
                definitions['TotalPriceInformationResponse']
            > = await apiClient.put(`/services/${serviceId}/calculateprice`, {
                Id: serviceId,
                Interval: interval,
                Quantities: quantities || [],
                CustomerEmail: customerEmail,
                RebateCodeIds: rebateCodeIds
            } as CalculatePriceQuery);
            
            thunkApi.dispatch(clearSubmitErrors(`promoCodes.${key}`));

            return thunkApi.fulfillWithValue({
                single,
                key,
                data: calculatePriceResponse.data as TotalPriceInformationResponse,
            });
            
        } catch (error) {
            return thunkApi.rejectWithValue(throwSubmissionError(error));
        }
    })

export const reset = createAction('/services/calculatePrice/RESET')

// Slice

const calcualtePriceSlice = createSlice({
    name: 'calculatePrice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(calculatePrice.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(calculatePrice.fulfilled, (state, action) => {
            let entity = state.entity || { [action.payload.key]: action.payload.data };

            entity[action.payload.key] = {
                ...action.payload.data,
                // @ts-ignore
                quantities: action.meta.arg.quantities
            };

            entity = {
                ...entity,
                ...(action.payload.data.AppliedCodes.length > 0 ? {
                    appliedCodes: action.payload.data
                } : {})
            }

            // different price was selected for the given time, so replace it with the new one
            if(!action.payload.single) {
                entity = Object.keys(entity).reduce((acc, key) => {
                    if (
                        key.split('_')[0] === action.payload.key.split('_')[0] &&
                        key.split('_')[1] !== action.payload.key.split('_')[1]
                    ) {
                        delete acc[key];
                        acc[action.payload.key] = {
                            ...action.payload.data,
                            // @ts-ignore
                            quantities: action.meta.arg.quantities
                        };
                    } 
    
                    return acc;
                }, entity);
            }

            state.entity = entity;
            state.loading = false;
            state.error = null;
        });

        builder.addCase(calculatePrice.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
        });

        builder.addCase(applyRebateCode.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(applyRebateCode.fulfilled, (state, action) => {
            state.loading = false;
            const oldEntity = state.entity || {};
            if(state.entity) {
                state.entity.appliedCodes = action.payload.data;
            }
        });

        builder.addCase(applyRebateCode.rejected, (state, action) => {
            state.loading = false;
        });


        builder.addCase(removeRebateCode.pending, (state, action) => {
            state.loading = true;
        });

        builder.addCase(removeRebateCode.fulfilled, (state, action) => {
            state.loading = false;
            const oldEntity = state.entity || {};
            if(state.entity) {
                state.entity.appliedCodes = action.payload.data;
            }
        });

        builder.addCase(removeRebateCode.rejected, (state, action) => {
            state.loading = false;
        });

        builder.addCase(reset, (state) => {
            state.entity = initialState.entity;
            state.error = initialState.error;
            state.loading = initialState.loading;
        })

    },
});

startCalculatePriceListener({
    matcher: calculatePrice.fulfilled.match,
    effect: async (action, api) => {
        const state = api.getState();
        
        if(!state.calculatePrice.loading && state.calculatePrice.entity) {
            api.dispatch({
                type: 'UPDATE_SERVICE_QUANTITIES',
                payload: []
            })
        }
    }
});

startCalculatePriceListener({
    matcher: removeRebateCode.fulfilled.match,
    effect: async (action, api) => {
        api.dispatch(resetForm('promoCodes'));
    }
});

export default calcualtePriceSlice;
