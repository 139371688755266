import { Args } from '@/types';
import {
    toDate as _toDate,
    format as _format,
    startOfWeek as _startOfWeek,
    endOfWeek as _endOfWeek,
    addMinutes as _addMinutes,
    addDays as _addDays,
    addWeeks as _addWeeks,
    addMonths as _addMonths,
    addYears as _addYears,
    getISODay as _getISODay,
    getMinutes as _getMinutes,
    getHours as _getHours,
    getDay as _getDay,
    getWeek as _getWeek,
    getMonth as _getMonth,
    getYear as _getYear,
    setMinutes as _setMinutes,
    setSeconds as _setSeconds,
    setHours as _setHours,
    setISODay as _setISODay,
    setDay as _setDay,
    startOfDay as _startOfDay,
    endOfDay as _endOfDay,
    startOfISOWeek as _startOfISOWeek,
    endOfISOWeek as _endOfISOWeek,
    startOfMonth as _startOfMonth,
    endOfMonth as _endOfMonth,
    max as _max,
    min as _min,
    isAfter as _isAfter,
    isBefore as _isBefore,
    isEqual as _isEqual,
    isSameMinute as _isSameMinute,
    isSameDay as _isSameDay,
    isSameMonth as _isSameMonth,
    subMonths as _subMonths,
    subDays as _subDays,
    subHours as _subHours,
    subMinutes as _subMinutes,
    differenceInDays as _differenceInDays,
    areIntervalsOverlapping as _areIntervalsOverlapping,
    eachDayOfInterval as _eachDayOfInterval,
    parse as _parse,
    parseISO,
    formatDistanceStrict as _formatDistanceStrict,
    isWithinInterval as _isWithinInterval,
    Locale,
    Interval,
    getISOWeek as _getISOWeek,
    isSameSecond as _isSameSecond,
    addHours as _addHours,
} from 'date-fns';
import { enUS, nb, sv, fi } from 'date-fns/locale';
import { TranslateFunction } from 'react-localize-redux';
import { isNumeric } from './common';
import { getCurrentLanguageCode } from './i18n';

//==================================================================================
// Module contains localised wrappers for date-fns functions and some custom utils
//==================================================================================

const locales: { [key: string]: Locale } = {
    en: enUS,
    no: nb,
    sv,
    fi,
};

const getLocale = (): Locale => locales[getCurrentLanguageCode()];

export { isValid } from 'date-fns';

export const parse = (
    dateString: string,
    formatString: string,
    backupDate: Date | string,
    options?: {} | undefined
): Date => _parse(dateString, formatString, toDate(backupDate), options);

export const toDate = (value: Date | string | number): Date =>
    typeof value === 'string' ? parseISO(value) : _toDate(value);

export const format = (date: Date | string, formatStr: string): string =>
    _format(toDate(date), formatStr, { locale: getLocale() });

export const startOfDay = (date: Date | string): Date => _startOfDay(toDate(date));

export const endOfDay = (date: Date | string): Date => _endOfDay(toDate(date));

export const startOfWeek = (date: Date | string, options: Object = {}): Date =>
    _startOfWeek(toDate(date), { locale: getLocale(), ...options });

export const endOfWeek = (date: Date | string, options: Object = {}): Date =>
    _endOfWeek(toDate(date), { locale: getLocale(), ...options });

export const startOfISOWeek = (date: Date | string): Date => _startOfISOWeek(toDate(date));

export const endOfISOWeek = (date: Date | string): Date => _endOfISOWeek(toDate(date));

export const startOfMonth = (date: Date | string): Date => _startOfMonth(toDate(date));

export const endOfMonth = (date: Date | string): Date => _endOfMonth(toDate(date));

export const addMinutes = (date: Date | string, amount: number): Date =>
    _addMinutes(toDate(date), amount);

export const addDays = (date: Date | string, amount: number): Date =>
    _addDays(toDate(date), amount);

export const addHours = (date: Date | string, amount: number): Date =>
    _addHours(toDate(date), amount);

export const addWeeks = (date: Date | string, amount: number): Date =>
    _addWeeks(toDate(date), amount);

export const addMonths = (date: Date | string, amount: number): Date =>
    _addMonths(toDate(date), amount);

export const addYears = (date: Date | string, amount: number): Date =>
    _addYears(toDate(date), amount);

export const max = (datesArray: (Date | string)[]): Date =>
    _max(datesArray.map((date) => toDate(date)));

export const min = (datesArray: (Date | string)[]): Date =>
    _min(datesArray.map((date) => toDate(date)));

export const isAfter = (date: Date | string, dateToCompare: Date | string): boolean =>
    _isAfter(toDate(date), toDate(dateToCompare));

export const isBefore = (date: Date | string, dateToCompare: Date | string): boolean =>
    _isBefore(toDate(date), toDate(dateToCompare));

export const isEqual = (dateLeft: Date | string, dateRight: Date | string): boolean =>
    _isEqual(toDate(dateLeft), toDate(dateRight));

export const isSameMinute = (dateLeft: Date | string, dateRight: Date | string): boolean =>
    _isSameMinute(toDate(dateLeft), toDate(dateRight));

export const isSameDay = (dateLeft: Date | string, dateRight: Date | string): boolean =>
    _isSameDay(toDate(dateLeft), toDate(dateRight));

export const isSameMonth = (dateLeft: Date | string, dateRight: Date | string): boolean =>
    _isSameMonth(toDate(dateLeft), toDate(dateRight));

export const subMonths = (dateLeft: Date | string, amount: number): Date =>
    _subMonths(toDate(dateLeft), amount);

export const subDays = (dateLeft: Date | string, amount: number): Date =>
    _subDays(toDate(dateLeft), amount);

export const subHours = (dateLeft: Date | string, amount: number): Date =>
    _subHours(toDate(dateLeft), amount);

export const subMinutes = (dateLeft: Date | string, amount: number): Date =>
    _subMinutes(toDate(dateLeft), amount);

export const differenceInDays = (dateLeft: number | Date, dateRight: number | Date): number =>
    _differenceInDays(toDate(dateLeft), toDate(dateRight));

export const getISODay = (date: Date | string): number => _getISODay(toDate(date));

export const getMinutes = (date: Date | string): number => _getMinutes(toDate(date));

export const getHours = (date: Date | string): number => _getHours(toDate(date));

export const getDay = (date: Date | string): number => _getDay(toDate(date));

export const getWeek = (date: Date | string): number => _getWeek(toDate(date));

export const getISOWeek = (date: Date | string): number => _getISOWeek(toDate(date));

export const getMonth = (date: Date | string): number => _getMonth(toDate(date));

export const getYear = (date: Date | string): number => _getYear(toDate(date));

export const setMinutes = (date: Date | string, day: number): Date =>
    _setMinutes(toDate(date), day);

export const setSeconds = (date: Date | string, day: number): Date =>
    _setSeconds(toDate(date), day);

export const setHours = (date: Date | string, day: number): Date => _setHours(toDate(date), day);

export const setISODay = (date: Date | string, day: number): Date => _setISODay(toDate(date), day);

export const setDay = (date: Date | string, day: number): Date => _setDay(toDate(date), day);

type IntervalWithStrings = {
    start: number | Date | string;
    end: number | Date | string;
};

export const toDateInterval = (intervalWithStrings: IntervalWithStrings): Interval => ({
    start: toDate(intervalWithStrings.start),
    end: toDate(intervalWithStrings.end),
});

export const areIntervalsOverlapping = (
    intervalLeft: IntervalWithStrings,
    intervalRight: IntervalWithStrings
): boolean => _areIntervalsOverlapping(toDateInterval(intervalLeft), toDateInterval(intervalRight));

export const eachDayOfInterval = (interval: IntervalWithStrings): Date[] =>
    _eachDayOfInterval(toDateInterval(interval));

export const isWithinInterval = (date: Date, interval: IntervalWithStrings): boolean =>
    _isWithinInterval(toDate(date), toDateInterval(interval));

export type FormatDistanceStrictOptions = Args<typeof _formatDistanceStrict>['args'][2];
export function formatDistanceStrict(
    date: Date | string,
    baseDate: Date | string,
    options: FormatDistanceStrictOptions = {}
) {
    const wordMapping = [
        'noll',
        'en',
        'två',
        'tre',
        'fyra',
        'fem',
        'sex',
        'sju',
        'åtta',
        'nio',
        'tio',
        'elva',
        'tolv',
    ];
    
    let formatted = _formatDistanceStrict(toDate(date), toDate(baseDate), {
        locale: getLocale(),
        ...options,
    });

    wordMapping.forEach((word, index) => {
        formatted = formatted.replace(word, String(index));
    });

    return formatted;
}

export function getRangeForLabel(label: string): { start?: Date | string; end?: Date | string } {
    const now = new Date();

    switch (label) {
        case 'today':
            return { start: startOfDay(now), end: endOfDay(now) };
        case 'thisWeek':
            return { start: startOfWeek(now), end: endOfWeek(now) };
        case 'past':
            return { end: now };
        case 'future':
            return { start: now };
        default:
            return {};
    }
}

export function formatMinutes(minutes: number, translate: TranslateFunction): string {
    const parsed = minutes ? parseSeconds(minutes * 60) : null;

    let lengthString = '';

    if (parsed) {
        const { days, minutes, hours } = parsed;
        if (minutes) {
            lengthString = `${minutes} ${translate('common.minutes')}`;
        }
        if (hours) {
            lengthString = `${hours} ${translate('common.hours')} ${lengthString}`;
        }
        if (days) {
            lengthString = `${days} ${translate('common.days')} ${lengthString}`;
        }
    }

    return lengthString;
}

export function parseSeconds(inputSeconds: number) {
    let seconds = inputSeconds;
    var days = Math.floor(inputSeconds / (3600 * 24));
    seconds -= days * 3600 * 24;
    var hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;
    var minutes = Math.floor(seconds / 60);
    seconds -= minutes * 60;

    return { days, hours, minutes, seconds };
}

export const sameOrBefore = (d1 = new Date(), d2 = new Date()) => {
    return _isSameSecond(d1, d2) ? true : isBefore(d1, d2) ? true : false;
};

export const sameOrAfter = (d1 = new Date(), d2 = new Date()) => {
    return _isSameSecond(d1, d2) ? true : isAfter(d1, d2) ? true : false;
};

export const isRangeBetween = (
    [r1a, r1b] = [new Date(), new Date()],
    [r2a, r2b] = [new Date(), new Date()],
    equalCounts = [true, true]
) => {
    const leftRangeCheck = equalCounts[0] ? sameOrBefore : isBefore;
    const rightRangeCheck = equalCounts[1] ? sameOrAfter : isAfter;

    return leftRangeCheck(r2a, r1a) && rightRangeCheck(r2b, r1b);
};


export function getDurationComputed(
    duration: number,
    unit = 'minutes' as 'minutes' | 'hours' | 'days'
) {
    if(unit === 'minutes') {
        return duration
    }

    let defaultDuration = duration;

    if (duration % 1440 === 0 || unit === 'days') {
        defaultDuration = duration / 1440;
    } else if (duration % 60 === 0 || unit === 'hours') {
        defaultDuration = duration / 60;
    }

    return defaultDuration;
}

export function getDurationBaseUnit(
    duration: number,
    baseUnit = 'minutes' as 'minutes' | 'hours' | 'days'
) {
    let durationBaseUnit = baseUnit;
    
    if (duration % 1440 === 0) {
        durationBaseUnit = 'days';
    } else if (duration % 60 === 0) {
        durationBaseUnit = 'hours';
    }

    return durationBaseUnit;
}

export function getDurationOriginalValue(
    duration: number,
    unit = 'minutes' as 'minutes' | 'hours' | 'days'
) {
    let defaultDuration = duration;

    if (unit === 'days') {
        defaultDuration = duration * 1440;
    } else if (unit === 'hours') {
        defaultDuration = duration * 60;
    }

    return defaultDuration;
}

export function convertToUnit(
    duration: number,
    fromUnit = 'minutes' as 'minutes' | 'hours' | 'days',
    toUnit: 'minutes' | 'hours' | 'days'
) {
    if(fromUnit === 'minutes' && toUnit === 'hours') {
        return duration / 60;
    } else if(fromUnit === 'minutes' && toUnit === 'days') {
        return duration / 60 / 24;
    } else if(fromUnit === 'hours' && toUnit === 'days') {
        return duration / 24;
    } else if(fromUnit === 'hours' && toUnit === 'minutes') {
        return duration * 60;
    } else if(fromUnit === 'days' && toUnit === 'minutes') {
        return duration * 24 * 60;
    } else if(fromUnit === 'days' && toUnit === 'hours') {
        return duration * 24;
    }

    return duration
}

type ConvertOptions = "minutes" | "hours" | "days";
type ConvertReturnType =
  | "second"
  | "minute"
  | "hour"
  | "day"
  | "month"
  | "year"
  | undefined;

export function convertToFormatOptions(
  unit: ConvertOptions
): ConvertReturnType {
  switch (unit) {
    case "minutes":
      return "minute";
    case "hours":
      return "hour";
    case "days":
      return "day";
    default:
      break;
  }
}

interface formatMinutesToWordsOptions {
    abbreaviated?: boolean
}

export function formatMinutesToWords(
    duration: number,
    options?: formatMinutesToWordsOptions & FormatDistanceStrictOptions
  ): string {
    const formattedMinutes = parseSeconds(duration * 60);
    const { days, minutes, hours } = formattedMinutes;
    let lengthString = "";
    const now = new Date();
  
    if (formattedMinutes && !options?.unit) {
      if (days) {
        const roundedDays = hours > 12 ? days + 1 : days;
        // @ts-ignore
        lengthString = formatDistanceStrict(now, addDays(now, roundedDays), {
          ...options,
        });
      }
      if (hours) {
        // @ts-ignore
        const hoursString = formatDistanceStrict(now, addHours(now, hours), {
          ...options,
        });
        lengthString = `${lengthString} ${hoursString}`;
      }
      if (minutes) {
        // @ts-ignore
        const minutesString = formatDistanceStrict(
          now,
          addMinutes(now, minutes),
          {
            ...options,
          }
        );
        // @ts-ignore
        lengthString = `${lengthString} ${minutesString}`;
      }
  
      if (!days && !minutes && !hours) {
        lengthString = formatDistanceStrict(now, now, {
          unit: options?.unit,
        });
      }
  
      if (options?.abbreaviated) {
        return lengthString
          .trim()
          .split(" ")
          .reduce((acc, curr) => {
            if (!isNumeric(curr)) {
              acc = `${acc}${curr.slice(0, 1)}`;
            } else {
              acc = `${acc} ${curr}`;
            }
  
            return acc;
          }, "")
          .replace(/\s/g, "");
      }
    } else if (options?.unit) {

        const add =
            options?.unit === 'day'
                ? addDays
                : options?.unit === 'hour'
                ? addHours
                : addMinutes
    
      lengthString = formatDistanceStrict(now, add(now, duration), {
        ...options,
      });

      const _duration = options.unit === 'day' ? duration * 60 * 24 * 60 : options.unit === 'hour' ? duration * 60 * 60 : duration * 60;
      const { minutes, hours }  = parseSeconds(_duration);
      let decimalPart = '';
      let wholePart = parseInt(lengthString.trim().split(' ')[0]);
      
      if(options.unit === 'day') {
        decimalPart = (hours / 24) ? (hours / 24).toPrecision(1) : '';
      } else if(options.unit === 'hour') {
        decimalPart = (minutes / 60) ? (minutes / 60).toPrecision(1) : '';
      }

      if (options?.abbreaviated) {
        return `${wholePart}${decimalPart}`;
      }
      let tmp = lengthString.split(" ");
      tmp.splice(1, 0, decimalPart);
      tmp = tmp.reduce((acc, curr, index) => {
        if(!isNaN(parseFloat(curr)) && index === 0) {
            acc.push(parseFloat(curr))
        } else if (!isNaN(parseFloat(curr)) && !isNaN(parseFloat(acc[index - 1])) && index > 0) {
            acc[index - 1] = acc[index - 1] + parseFloat(curr);
        } else if(curr) {
            acc.push(curr);
        }
        return acc;
      }, [] as any[]);

      return tmp.join(" ");
    }
  
    return lengthString.trim();
  }

  export const formatTimeToWords = (
    duration: number,
    unit: "minute" | "hour" | "day",
    translate: TranslateFunction,
    style: 'normal' | 'narrow' | 'short' | 'hide' = 'normal'
  ) => {
    const _duration = unit === 'day' ? duration * 60 * 24 * 60 : unit === 'hour' ? duration * 60 * 60 : duration * 60;
     
    const formattedMinutes = parseSeconds(_duration);
    const { days, minutes, hours } = formattedMinutes;
  
    const sliceArgs = style === 'short' ? [0, 1] : style === 'narrow' ? [0, 3] : [];
    const daySingular = style !== 'hide' ? translate('common.day').toString().slice(...sliceArgs) : '';
    const dayPlural = style !== 'hide' ? translate('common.days').toString().slice(...sliceArgs) : '';
    const hourSingular = style !== 'hide' ? translate('common.hour').toString().slice(...sliceArgs) : '';
    const hourPlural = style !== 'hide' ? translate('common.hours').toString().slice(...sliceArgs) : '';
    const minuteSingular = style !== 'hide' ? translate('common.minute').toString().slice(...sliceArgs) : '';
    const minutePlural = style !== 'hide' ? translate('common.minutes').toString().slice(...sliceArgs) : '';
  
    let returnValue = ''
    
    if (unit === "day") {
      const daySingleOrPlural = days > 1 ? dayPlural : daySingular;
      returnValue = `${
        days + parseFloat((hours / 24 + minutes / (24 * 60)).toPrecision(1))
      } ${daySingleOrPlural}`;
    } else if (unit === "hour") {
      const hourSingleOrPlural = days * 24 + hours > 1 ? hourPlural : hourSingular;
      returnValue = `${days * 24 + hours + parseFloat((minutes / 60).toPrecision(1))} ${hourSingleOrPlural}`;
    } else if (unit === "minute") {
      const minuteSingleOrPlural = days * 24 * 60 + hours * 60 + minutes > 1 ? minutePlural : minuteSingular;
      returnValue = `${days * 24 * 60 + hours * 60 + minutes} ${minuteSingleOrPlural}`;
    }
  
    return returnValue.trim();
  };

export const dateRenderer = (key: string) => (row: any) => row[key] ? format(row[key], 'yyyy-MM-dd p') : null;


export const parseTime = (time: String) => {
    const strs = time.split(':');
    let dateObj = new Date();

    dateObj = setHours(dateObj, parseInt(strs[0]));
    dateObj = setMinutes(dateObj, parseInt(strs[1]));
    dateObj = setSeconds(dateObj, parseInt(strs[2]));

    return dateObj;
};

export const renderTime = (time: string) => format(parseTime(time), 'HH:mm');