import React, { Component } from 'react';
import { withLocalize, LocalizeContextProps } from 'react-localize-redux';
import { connect } from 'react-redux';

import Loader from '@/components/Loader';
import PageHeader from '@/components/PageHeader';
import * as actions from '@/store/actions';
import Newsletters from './Newsletters';
import { Checkbox, Input, CheckboxProps, InputOnChangeData } from 'semantic-ui-react';
import { ListState, Newsletter, NewsletterTemplate, NewsletterEmailTemplate } from '@/types';
import { Dispatch, compose } from 'redux';

import { RootState } from 'MyTypes';

const PAGE_SIZE = 25;

interface Props extends LocalizeContextProps {
    dispatch: Dispatch;
    newslettersTemplates: ListState<NewsletterTemplate>;
    newslettersLog: ListState<Newsletter>;
    newslettersEmailTemplates: ListState<NewsletterEmailTemplate>;
}

class NewsletterLogLoader extends Component<Props> {
    state = {
        ReceiverContains: undefined,
        Sent: true,
    };

    createFetchNewsletterTemplatesAction = (listParams = {}) => {
        const { ReceiverContains, Sent } = this.state;
        return actions.FETCH_NEWSLETTER_TEMPLATES.request({
            Take: PAGE_SIZE,
            ReceiverContains,
            Sent,
            OrderByDesc: 'CreatedDate',
            ...listParams,
        });
    };

    createFetchNewsletterEmailTemplatesAction = (listParams = {}) => {
        const { ReceiverContains, Sent } = this.state;
        return actions.FETCH_NEWSLETTER_TEMPLATES.request({
            Take: PAGE_SIZE,
            ReceiverContains,
            Sent,
            OrderByDesc: 'CreatedDate',
            ...listParams,
        });
    };

    createFetchNewsletterLogAction = (listParams = {}) => {
        const { ReceiverContains, Sent } = this.state;
        return actions.FETCH_NEWSLETTER_LOG.request({
            Take: PAGE_SIZE,
            ReceiverContains,
            Sent,
            OrderByDesc: 'CreatedDate',
            ...listParams,
        });
    };

    handleSearchChange = (e: React.SyntheticEvent, data: InputOnChangeData) => {
        this.setState({ ReceiverContains: data.value }, () => {
            this.props.dispatch(this.createFetchNewsletterTemplatesAction({ Skip: 0 }));
            this.props.dispatch(this.createFetchNewsletterLogAction({ Skip: 0 }));
            this.props.dispatch(this.createFetchNewsletterEmailTemplatesAction({ Skip: 0 }));
        });
    };

    handleSentCheckboxChange = (e: React.SyntheticEvent, data: CheckboxProps) => {
        this.setState({ Sent: data.checked }, () => {
            this.props.dispatch(this.createFetchNewsletterTemplatesAction({ Skip: 0 }));
            this.props.dispatch(this.createFetchNewsletterLogAction({ Skip: 0 }));
            this.props.dispatch(this.createFetchNewsletterEmailTemplatesAction({ Skip: 0 }));
        });
    };

    render() {
        const { translate } = this.props;
        

        return (
            <>
                <PageHeader
                    breadcrumbs={[
                        { to: '/newsletters', content: translate('newsletters.title') },
                    ]}
                    content={
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                                alignItems: 'center',
                            }}
                        >
                            <Checkbox
                                toggle
                                label={translate('newsletterLog.Sent')}
                                style={{ marginRight: 32 }}
                                onChange={this.handleSentCheckboxChange}
                                checked={this.state.Sent}
                            />
                            <Input
                                icon="search"
                                style={{ float: 'right' }}
                                floated="right"
                                onChange={this.handleSearchChange}
                                value={this.state.ReceiverContains}
                            />
                        </div>
                    }
                />
                <Loader
                    requirements={[
                        {
                            action: (listParams) => this.createFetchNewsletterLogAction(listParams),
                            data: this.props.newslettersLog,
                        },
                        {
                            action: (listParams) => this.createFetchNewsletterTemplatesAction(listParams),
                            data: this.props.newslettersTemplates,
                        },
                        {
                            action: (listParams) => this.createFetchNewsletterEmailTemplatesAction(listParams),
                            data: this.props.newslettersEmailTemplates,
                        },
                    ]}
                >
                    <Newsletters {...this.props} pageSize={PAGE_SIZE} />
                </Loader>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => {
    return {
        newslettersLog: state.newsletters.log,
        newslettersTemplates: state.newsletters.templates,
        newslettersEmailTemplates: state.newsletters.emailTemplates
    };
};

export default compose<React.ComponentType>(
    connect(mapStateToProps),
    withLocalize
)(NewsletterLogLoader);
