import React, { Component } from 'react';
import { Tab, TabProps, Label, Button } from 'semantic-ui-react';
import { LocalizeContextProps } from 'react-localize-redux';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'MyTypes';
import { ListState, Newsletter, NewsletterTemplate, NewsletterEmailTemplate } from '@/types';
import NewsletterLog from '../NewsletterLog/NewsletterLog';
import NewsletterTemplates from '../NewsletterTemplates/NewsletterTemplates';
import { Link } from 'react-router-dom';

interface Props extends LocalizeContextProps {
    dispatch: Dispatch;
    newslettersTemplates: ListState<NewsletterTemplate>;
    newslettersLog: ListState<Newsletter>;
    newslettersEmailTemplates: ListState<NewsletterEmailTemplate>;
}

interface State {
    activeTabIndex: number;
}

class Newsletters extends Component<Props, State> {
    state = {
        activeTabIndex: 0,
    };

    render() {
        const { translate, newslettersLog, newslettersTemplates, newslettersEmailTemplates } = this.props;
        const { activeTabIndex } = this.state;

        const panes = [
            {
                menuItem: {
                    content: (
                        <div>
                            {translate('newsletters.tabs.log')}&nbsp;
                            <Label basic>{newslettersLog.total}</Label>
                        </div>
                    ),
                    key: 'newsletterlog',
                },
                render: () => (
                    <Tab.Pane attached={false}>
                        {/* @ts-ignore */}
                        <NewsletterLog {...this.props} newsletters={newslettersLog} />
                    </Tab.Pane>
                ),
            },
            {
                menuItem: {
                    content: (
                        <div>
                            {translate('newsletters.tabs.tamplates')}&nbsp;
                            <Label basic>{newslettersTemplates.total}</Label>
                        </div>
                    ),
                    key: 'newslettertemplates',
                },
                render: () => (
                    <Tab.Pane attached={false}>
                        {/* @ts-ignore */}
                        <NewsletterTemplates {...this.props} templates={newslettersTemplates} />
                    </Tab.Pane>
                ),
            },
        ];

        return (
            <>
                {activeTabIndex === 0 ? (
                    <Button
                        floated="right"
                        positive
                        content={translate('sendNewsletter.title')}
                        as={Link}
                        to="/newsletters/send"
                        size="large"
                    />
                ) : activeTabIndex === 1 ? (
                    <Button
                        floated="right"
                        positive
                        content={translate('newsletterTemplates.newTemplate')}
                        as={Link}
                        to="/newsletters/new"
                        size="large"
                    />
                ) : null}
                <Tab
                    onTabChange={this.handleTabChange}
                    panes={panes}
                    activeIndex={activeTabIndex}
                    menu={{ secondary: true }}
                />
            </>
        );
    }
    
    handleTabChange = (e: React.MouseEvent, { activeIndex, panes }: TabProps) => {
        this.setState({ activeTabIndex: Number(activeIndex) })
    };
}


const mapStateToProps = (state: RootState, props: any) => {

    return {
        newslettersLog: state.newsletters.log,
        newslettersTemplates: state.newsletters.templates,
        newslettersEmailTemplates: state.newsletters.emailTemplates
    };
};

export default connect(mapStateToProps)(Newsletters);
